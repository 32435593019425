<template>
  <v-container>
    <v-dialog v-model="examplesDialog" max-width="800" persistent>
      <v-card>
        <v-card-title>{{ $i18n.translate("Examples") }}</v-card-title>
        <v-card-text class="text-center"><img :src="dialogImage" alt="Sample Barcodes" width="600" /> </v-card-text>
        <v-card-actions
          ><v-spacer></v-spacer
          ><v-btn class="primary" @click="examplesDialog = false">{{ $i18n.translate("Close") }}</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="8">
        <v-card :loading="isBusy">
          <v-card-title class="headline eur-card-title">
            {{ $i18n.translate("Claim Rebate") }}
          </v-card-title>
          <v-card-subtitle class="eur-card-subtitle">
            <span v-if="this.promotion"
              >{{ this.promotion.name }} (<a @click="showUploadedFilesDialog()">{{ this.promotion.promotionKey }}</a
              >)</span
            >
          </v-card-subtitle>
          <v-card-text class="mt-5">
            <v-dialog v-model="uploadedFilesDialog" max-width="800" persistent>
              <v-card>
                <v-card-title>{{ $i18n.translate("Files") }}</v-card-title>
                <v-card-text>
                  <v-container class="ma-0 pa-0">
                    <v-row dense>
                      <v-col cols="1" dense></v-col>
                      <v-col cols="11" dense>
                        <ol>
                          <li v-for="(upload, i) in uploads" :key="i">
                            <a :href="upload.href" target="_blank">
                              {{ upload.originalFilename }}
                            </a>
                          </li>
                        </ol>
                      </v-col>
                    </v-row>
                    <v-row class="text-right" dense>
                      <v-col cols="12" dense>
                        <v-btn class="primary" @click="uploadedFilesDialog = false">Close</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>

            <ApiError :errors="errors"></ApiError>

            <v-stepper v-model="step" alt-labels v-if="!claimSubmitted">
              <v-stepper-header>
                <v-stepper-step :editable="step > 1" step="1">{{ $i18n.translate("Details") }}</v-stepper-step>

                <v-divider></v-divider>
                <v-stepper-step :editable="step > 2 || valid.step1" step="2">Products</v-stepper-step>
                <v-divider v-if="supportingDocumentation"></v-divider>
                <v-stepper-step
                  :editable="step > 3 || (valid.step1 && valid.step2 && claimProducts.length > 0)"
                  step="3"
                  v-if="supportingDocumentation"
                >
                  {{ $i18n.translate("Supporting Documentation") }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  :editable="step > 4 || (valid.step1 && valid.step2 && valid.step3)"
                  :step="supportingDocumentation ? 4 : 3"
                  class="text-center"
                >
                  {{ $i18n.translate("Review Claim Details") }}
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-form ref="step1Form" @submit.prevent="onNextStep" v-if="!isBusy" v-model="valid.step1">
                    <v-container v-if="this.claimFields">
                      <v-row v-for="(claimFieldRow, i) in this.claimFieldsByRow" :key="i">
                        <template v-for="(claimField, j) in claimFieldRow">
                          <v-col cols="12" v-if="claimField.newCategory" :key="j + '-category'">
                            <v-subheader class="pl-0"
                              ><h3>{{ claimField.category }}</h3></v-subheader
                            >
                            <v-divider></v-divider>
                          </v-col>
                          <v-col :cols="claimField.cols" :key="j">
                            <ClaimField
                              dense
                              :publicField="true"
                              :label="claimField.name"
                              :hint="claimField.description"
                              :promotion="promotion"
                              :claimField="claimField"
                              :showOtherItemOrganization="false"
                              v-model="claimFieldValues[claimField.id]"
                              :error-messages="$error.getValidationError(errors, claimField.name)"
                              @input="$error.clearValidationError(errors, claimField.name)"
                              @otherSelected="updateSonyDealerFieldsVisibility($event)"
                            >
                            </ClaimField>
                          </v-col>
                        </template>
                      </v-row>
                    </v-container>

                    <v-btn color="primary" @click="onNextStep">
                      {{ $i18n.translate("Continue") }}
                    </v-btn>
                    <v-btn @click="onCancel" text>{{ $i18n.translate("Cancel") }}</v-btn>
                  </v-form>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-form ref="step2Form" @submit.prevent="onNextStep" v-if="!isBusy" v-model="valid.step2">
                    <v-container>
                      <v-subheader
                        ><h3>{{ $i18n.translate("Select Product") }}</h3></v-subheader
                      >
                      <v-row>
                        <v-col offset="1" sm="9" md="9" lg="6">
                          <v-select
                            :items="promotion.promotionProducts"
                            v-model="selectedPromotionProducts"
                            :label="$i18n.translate('Select a Product')"
                            item-value="id"
                            :item-text="item => `${item.product.productKey}`"
                            multiple
                            return-object
                            :rules="[requireProduct]"
                            class="required promotion_products_select"
                            :hint="$i18n.translate('You must select at least 1 product for this claim')"
                            attach
                            auto
                            :menu-props="{ zIndex: '9999' }"
                          ></v-select>
                        </v-col>
                        <v-col sm="2" md="2" lg="2">
                          <v-btn color="success" @click="onAddProduct">{{ $i18n.translate("Add") }}</v-btn>
                        </v-col>
                      </v-row>
                      <v-subheader
                        ><h3>{{ $i18n.translate("Selected Products") }}</h3></v-subheader
                      >
                      <v-row>
                        <v-col offset="1" cols="10">
                          <v-data-table :headers="claimProductHeaders" :items="claimProducts">
                            <template v-slot:item.serialNumber="{ item }">
                              <v-container>
                                <v-row>
                                  <v-col cols="12" v-if="item.promotionProduct.enableSerialNumbers">
                                    <v-text-field
                                      :label="$i18n.translate('Serial Number')"
                                      v-model="item.serialNumber"
                                      @keydown="keyHandler($event)"
                                      :class="item.promotionProduct.enableSerialNumbers ? 'required' : ''"
                                      :rules="
                                        item.promotionProduct.enableSerialNumbers
                                          ? [requireSerialNumber(item), checkDuplicatedSerialNumber(item)]
                                          : []
                                      "
                                      @blur="formatSerialNumber(item)"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="6" v-else>
                                    <v-text-field
                                      :label="$i18n.translate('Quantity')"
                                      type="number"
                                      min="1"
                                      max="9999999"
                                      v-model="item.quantity"
                                      :rules="rules.quantity"
                                      class="required"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </template>
                            <template v-slot:item.actions="{ item }">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon small v-bind="attrs" v-on="on" @click="onDeleteProduct(item)"
                                    >mdi-delete</v-icon
                                  >
                                </template>
                                <span>{{ $i18n.translate("Delete Product") }}</span>
                              </v-tooltip>

                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon small v-bind="attrs" v-on="on" @click="onDuplicateProduct(item)"
                                    >mdi-content-duplicate</v-icon
                                  >
                                </template>
                                <span>{{ $i18n.translate("Duplicate Product") }}</span>
                              </v-tooltip>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-container>

                    <!-- <v-btn color="primary" :disabled="!valid.step2 || claimProducts.length == 0" @click="onNextStep"> -->
                    <v-btn color="primary" @click="onNextStep">
                      {{ $i18n.translate("Continue") }}
                    </v-btn>
                    <v-btn @click="onCancel" text>{{ $i18n.translate("Cancel") }}</v-btn>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content step="3" v-if="supportingDocumentation">
                  <v-form ref="uploadForm" @submit.prevent="onNextStep" v-if="!isBusy" v-model="valid.step3">
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <h3 v-if="selectedLocale.languageType.name == 'fr'">
                            Presque fini! Téléchargez la documentation de sauvegarde requise pour cette remise.
                          </h3>
                          <h3 v-else>
                            Almost Done! Upload the required backup documentation for this rebate.
                          </h3>
                          <ul>
                            <li v-if="selectedLocale.languageType.name == 'fr'">
                              Veuillez utiliser l'outil de téléchargement de documents ci-dessous pour télécharger une
                              copie de votre facture / reçu d'achat avec le numéro de série du modèle éligible réclamé
                              inclus.
                            </li>
                            <li v-else>
                              Please utilize the Upload documents tool below to upload a copy of your purchase
                              invoice/receipt with the serial number of the eligible model claimed included.
                            </li>
                            <li>
                              <span v-if="selectedLocale.languageType.name == 'fr'">
                                Si votre facture / reçu d'achat ne fournit pas le numéro de série du modèle éligible
                                réclamé, une image de la plaque de numéro de modèle / série, ou le code CUP sur la
                                boîte, du modèle éligible réclamé est requise.
                              </span>
                              <span v-else>
                                If your purchase invoice/receipt does not provide the serial number of the eligible
                                model claimed an image of the model/serial number plate, or UPC code on the box, of the
                                eligible model claimed is required.
                              </span>
                              <ul>
                                <li v-if="selectedLocale.languageType.name == 'fr'">
                                  Le numéro de série et le numéro de modèle doivent être clairement visibles.
                                  <a @click="examplesDialog = true">Cliquez ici</a> pour des exemples.
                                </li>
                                <li v-else>
                                  The serial number and model number must be clearly visible.
                                  <a @click="examplesDialog = true">Click here</a> for examples.
                                </li>
                              </ul>
                            </li>
                            <li v-if="selectedLocale.languageType.name == 'fr'">
                              Jusqu'à 12 documents pour la vérification de l'activité sont autorisés.
                            </li>
                            <li v-else>
                              Up to 12 documents for activity verification are allowed.
                            </li>
                          </ul>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <UploadField v-model="claimUploads" :rules="[requireUpload]" :public="true"></UploadField>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-btn color="primary" @click="onNextStep">
                      {{ $i18n.translate("Continue") }}
                    </v-btn>
                    <v-btn @click="onCancel" text>{{ $i18n.translate("Cancel") }}</v-btn>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content :step="supportingDocumentation ? 4 : 3">
                  <v-form @submit.prevent="onSubmit" v-model="valid.step4">
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title primary-title>
                              {{ $i18n.translate("Review Claim Details") }}
                            </v-card-title>
                            <v-card-text>
                              <v-simple-table dense>
                                <template v-slot:default>
                                  <tbody>
                                    <tr v-if="isAdminOrPrincipalOwner">
                                      <th>
                                        {{ $i18n.translate("Participant selected to claim on behalf") }}
                                      </th>
                                      <td>
                                        {{ participantSelectedToClaimOnBehalf.fullName }}
                                      </td>
                                    </tr>
                                    <tr v-for="(claimField, i) in claimFields" :key="i + '-values'">
                                      <th width="30%" class="text-right">{{ claimField.name }}</th>
                                      <td width="70%">
                                        <span v-if="claimField.claimFieldType.date === true">
                                          {{
                                            claimFieldValues[claimField.id] && claimFieldValues[claimField.id].name
                                              ? claimFieldValues[claimField.id].name
                                              : claimFieldValues[claimField.id]
                                                | formatDateClient("MM/DD/YYYY", selectedClient)
                                          }}
                                        </span>
                                        <span v-else-if="claimField.claimFieldType.name == 'INVOICE_AMOUNT_TYPE'">
                                          {{ claimFieldValues[claimField.id] | toNumber(2) }}
                                          {{ promotion.country ? promotion.country.currencyName : "" }}
                                        </span>
                                        <span
                                          v-else-if="
                                            claimField.claimFieldType.name == 'END_USER_REGION_TYPE' ||
                                              claimField.claimFieldType.name == 'REGION_TYPE'
                                          "
                                        >
                                          {{
                                            claimFieldValues[claimField.id]
                                              ? claimFieldValues[claimField.id].isoAlpha2
                                              : ""
                                          }}
                                        </span>
                                        <span v-else>
                                          {{
                                            claimFieldValues[claimField.id] && claimFieldValues[claimField.id].name
                                              ? claimFieldValues[claimField.id].name
                                              : claimFieldValues[claimField.id]
                                          }}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table></v-card-text
                            >
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title primary-title>
                              {{ $i18n.translate("Products") }}
                            </v-card-title>
                            <v-card-text>
                              <v-simple-table dense>
                                <template v-slot:default>
                                  <tbody>
                                    <tr v-for="(claimProduct, i) in claimProducts" :key="i + '-products'">
                                      <th width="30%" class="text-right">
                                        {{ claimProduct.promotionProduct.product.productKey }}
                                      </th>
                                      <td width="70%">
                                        <span v-if="claimProduct.promotionProduct.enableSerialNumbers">
                                          {{ $i18n.translate("Quantity") }}: 1, {{ $i18n.translate("Serial Number") }}:
                                          {{ claimProduct.serialNumber }}</span
                                        >
                                        <span v-else>
                                          {{ $i18n.translate("Quantity") }}: {{ claimProduct.quantity }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <th width="30%" class="text-right">
                                        Total Products Claimed
                                      </th>
                                      <td width="70%">
                                        <span>{{ totalProductsClaimed }}</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table></v-card-text
                            >
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row v-if="supportingDocumentation">
                        <v-col cols="12">
                          <v-card>
                            <v-card-title primary-title>
                              {{ $i18n.translate("Supporting Documentation") }}
                            </v-card-title>
                            <v-card-text
                              ><v-simple-table dense>
                                <template v-slot:default>
                                  <tbody>
                                    <tr v-for="(claimUpload, i) in claimUploads.existing" :key="i + '-uploads'">
                                      <th width="30%" class="text-right">File {{ i + 1 }}</th>
                                      <td width="70%">
                                        <a :href="claimUpload.href" target="_blank">{{
                                          claimUpload.originalFilename
                                        }}</a>
                                        ({{ claimUpload.contentType }})
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table></v-card-text
                            >
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title primary-title>
                              {{ $i18n.translate("Sony email communications") }}
                            </v-card-title>
                            <v-card-text v-if="selectedLocale.languageType.name == 'fr'">
                              <p>
                                <v-checkbox
                                  label="Je souhaite recevoir des communications par e-mail de Sony Imaging Products &amp; Solutions - Americas"
                                  v-model="optIn"
                                ></v-checkbox>

                                En cliquant sur 'Soumettre', je confirme que j'accepte le Sony Electronics
                                <a href="https://products.sel.sony.com/SEL/legal/privacy.html" target="_blank"
                                  >Politique de confidentialité </a
                                >et certifie que je suis un résident des États-Unis. Vous pouvez
                                <a href="https://m.info.pro.sony.com/webApp/SonyPSAUnsubscribe_01" target="_blank"
                                  >se désinscrire</a
                                >
                                à tout moment.
                              </p>
                            </v-card-text>
                            <v-card-text v-else>
                              <p>
                                <v-checkbox
                                  label="I'd like to receive Email communications from Sony Imaging Products &amp; Solutions - Americas."
                                  v-model="optIn"
                                ></v-checkbox>

                                By clicking 'Submit', I confirm that I agree to the Sony Electronics
                                <a href="https://products.sel.sony.com/SEL/legal/privacy.html" target="_blank"
                                  >Privacy Policy </a
                                >and certify that I am a U.S. resident. You may
                                <a href="https://m.info.pro.sony.com/webApp/SonyPSAUnsubscribe_01" target="_blank"
                                  >Unsubscribe</a
                                >
                                at any time.
                              </p>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title primary-title>
                              {{ $i18n.translate("Terms &amp; Conditions") }}
                            </v-card-title>
                            <v-card-text>
                              <p>
                                {{ termsAndConditions.description }}
                              </p>

                              <v-checkbox
                                :label="$i18n.translate('I accept all Terms &amp; Conditions')"
                                v-model="terms"
                                :rules="rules.terms"
                                value="value"
                                >{{ $i18n.translate("Approve Terms &amp; Conditions") }}</v-checkbox
                              >
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-btn color="primary" :disabled="!valid.step4" :loading="isBusy" @click="onSubmit()">
                      {{ $i18n.translate("Submit") }}
                    </v-btn>

                    <v-btn @click="onCancel" v-if="!isBusy" text>{{ $i18n.translate("Cancel") }}</v-btn>
                    <br />
                    <GoogleReCaptcha></GoogleReCaptcha>
                  </v-form>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
            <v-container v-else class="text-center">
              <v-icon x-large color="green">mdi-check-circle-outline</v-icon>
              <h3 class="ma-5">{{ $i18n.translate("Rebate Claim Submitted Successfully") }}</h3>
              <p>
                {{ $i18n.translate("Your claim has been received and is currently being processed.") }}
              </p>
              <p class="mb-0">{{ $i18n.translate("Claim Number") }}</p>
              <h3>{{ claimNumber }}</h3>
              <p class="text-caption mt-10" v-if="selectedLocale.languageType.name == 'fr'">
                Avez-vous des questions? N'hésitez pas à nous envoyer un e-mail à<a
                  :href="'mailto:' + selectedProgram.programEmail"
                  >{{ selectedProgram.programEmail }}</a
                >, ou appelez simplement {{ selectedProgram.programPhone }}. Notre équipe de service à la clientèle est
                disponible entre 8h30 et 17h00 (ct) du lundi au vendredi pour vous aider.
              </p>
              <p class="text-caption mt-10" v-else>
                Have any questions? Don't hesitate to email us at
                <a :href="'mailto:' + selectedProgram.programEmail">{{ selectedProgram.programEmail }}</a
                >, or simply call {{ selectedProgram.programPhone }}. Our customer care team is available between 8:30am
                and 5:00pm (ct) Monday through Friday to assist.
              </p>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="4"> <EurCard :promotion="promotion" :showClaimButton="false"/></v-col>
    </v-row>
  </v-container>
</template>

<script>
import EurCard from "./EurCard.vue";
import GoogleReCaptcha from "../../gapp-components/components/display/GoogleReCaptcha.vue";
import UploadField from "../../gapp-components/components/fields/UploadField.vue";
import ClaimField from "../../gapp-components/components/fields/ClaimField.vue";
import ApiError from "../../gapp-components/components/display/ApiError.vue";
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import Vue from "vue";

export default {
  name: "EurClaim",
  metaInfo() {
    return {
      title: this.$i18n.translate("Claim End User Rebate")
    };
  },
  components: {
    ApiError,
    ClaimField,
    UploadField,
    GoogleReCaptcha,
    EurCard
  },
  data: () => ({
    step: "1",
    valid: {
      step1: false,
      step2: false,
      step3: false,
      step4: false
    },
    isBusy: false,
    isNew: false,
    isEditing: true,
    promotionId: 0,
    errors: {},
    serialNumberRegex: "",

    promotion: {},

    claimFields: [],
    claimFieldsByRow: [],
    claimFieldValues: {},
    defaultTermsAndConditions: [],
    selectedPromotionProducts: [],
    claimProducts: [],
    claimProductHeaders: [
      {
        value: "promotionProduct.product.productKey",
        text: "Model",
        align: "center"
      },
      {
        value: "serialNumber",
        text: "",
        sortable: false
      },
      {
        value: "actions",
        text: "Actions",
        align: "center",
        sortable: false
      }
    ],

    claimSubmitted: false,

    claimStage: null,

    claimUploads: {
      existing: [],
      deleted: []
    },

    rules: {
      required: [v => !!v || "Field is required"],
      participant: [v => !!v || "Participant is required"],
      quantity: [v => !!v || "Quantity is required"],
      terms: [v => !!v || "Terms is required"]
    },

    form: {},
    uploads: {},
    uploadedFilesDialog: false,

    participantSelectedToClaimOnBehalf: null,
    claimNumber: "",
    q1: false,
    zeroSales: false,
    zeroSalesItems: [],
    selectedZeroSalesPeriod: null,
    formattedZeroSalesPeriod: null,
    saveEndUserForLater: false,
    selectedEndUser: null,

    // preferredAwardVehicle: undefined,
    availableAwardVehicles: [],

    participantSkipClaimDocumentation: false,
    promotionSkipClaimDocumentation: false,
    promotionTypeDocumentationRequired: false,
    supportingDocumentation: true,
    terms: false,
    examplesDialog: false,
    dialogImage: require("@/assets/sample-barcodes.png"),
    optIn: true
  }),
  created() {
    this.promotionId = parseInt(this.$route.params.id);
    if (this.$route.params.id == 0) {
      this.isNew = true;
    }
    this.participantSkipClaimDocumentation = false;
    this.promotionSkipClaimDocumentation = this.promotion.skipClaimDocumentation;
    this.fetchData();
    this.loadZeroSalesMonths();
  },
  watch: {
    claimProducts: {
      handler() {
        this.$refs.step2Form.validate();
      },
      deep: true
    },
    claimUploads: {
      handler() {
        this.$refs.uploadForm.validate();
      },
      deep: true
    },
    selectedZeroSalesPeriod() {
      this.formattedZeroSalesPeriod = moment
        .tz(this.selectedZeroSalesPeriod, this.selectedClient.timezone)
        .format("MMMM YYYY");
    },
    step: {
      immediate: true,
      handler() {
        this.$forceUpdate();
      }
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram", "selectedClient", "selectedLocale"]),
    isAdminOrPrincipalOwner() {
      //   return ["500", "510", "600", "610", "700", "710", "800", "810"].includes(
      //     this.selectedParticipant.participantType.participantTypeKey
      //   );
      return false;
    },
    totalProductsClaimed() {
      let amount = 0;
      for (let cp of this.claimProducts) {
        if (cp.quantity) {
          amount += eval(cp.quantity);
        } else {
          amount += 1;
        }
      }
      return amount;
    },
    termsAndConditions() {
      let selectedLocaleName = this.selectedLocale.languageType.name;
      if (this.promotion && this.promotion.promotionType && this.promotion.promotionType.termsAndConditions) {
        let promotionTypeTermsAndConditions = this.promotion.promotionType.termsAndConditions.filter(
          tac => tac.language.name == selectedLocaleName
        );
        if (promotionTypeTermsAndConditions.length > 0) {
          return promotionTypeTermsAndConditions[0];
        }
      }
      if (this.defaultTermsAndConditions) {
        let programTermsAndConditions = this.defaultTermsAndConditions.filter(
          tac => tac.language.name == selectedLocaleName
        );
        if (programTermsAndConditions.length > 0) {
          return programTermsAndConditions[0];
        }
      }

      return {
        description: "Terms And Conditions error. Not specified."
      };
    }
  },
  methods: {
    isNumber(input) {
      let numericInput = parseInt(input);
      return typeof numericInput === "number" && !isNaN(numericInput);
    },

    onNextStep() {
      switch (this.step) {
        case "1":
          if (!this.valid.step1) {
            this.$refs.step1Form.validate();
          } else {
            this.step = "2";
          }
          break;
        case "2":
          if (!this.valid.step2) {
            this.$refs.step2Form.validate();
          } else {
            this.step = "3";
          }
          break;
        case "3":
          if (!this.valid.step3) {
            this.$refs.uploadForm.validate();
          } else {
            this.step = "4";
          }
      }
    },
    checkDuplicatedSerialNumber(item) {
      let count = 0;
      this.claimProducts.forEach(element => {
        if (
          element.serialNumber &&
          element.promotionProduct.product.id == item.promotionProduct.product.id &&
          element.serialNumber == item.serialNumber
        ) {
          count++;
        }
      });
      if (count > 1) {
        return "Serial number duplicated";
      }
      return true;
    },
    requireSerialNumber(item) {
      if (item.promotionProduct.enableSerialNumbers) {
        if (item.serialNumber) {
          if (item.promotionProduct.product.serialNumberRegex) {
            let regex = RegExp("^" + item.promotionProduct.product.serialNumberRegex);
            if (regex.test(item.serialNumber)) {
              return true;
            } else {
              return "Invalid Serial Number";
            }
          } else {
            return true;
          }
        } else {
          return "Serial Number required";
        }
      } else {
        return false;
      }
    },
    requireProduct() {
      if (this.claimProducts.length > 0) {
        return true;
      } else {
        return "At least 1 product is required";
      }
    },
    requireUpload() {
      if (this.claimUploads.existing.length > 0 && !this.promotionSkipClaimDocumentation) {
        return true;
      } else {
        return "At least 1 upload is required";
      }
    },
    onAddProduct() {
      let promotionProducts = this.claimProducts;
      this.selectedPromotionProducts.some(promotionProduct => {
        let found = promotionProducts.findIndex(
          needle => needle.promotionProduct.product.id == promotionProduct.product.id
        );
        if (found == -1) {
          promotionProducts = promotionProducts.concat([{ promotionProduct: promotionProduct }]);
        } else {
          if (promotionProduct.enableSerialNumbers) {
            promotionProducts = promotionProducts.concat([{ promotionProduct: promotionProduct }]);
          }
        }
      });
      this.claimProducts = promotionProducts;
      this.selectedPromotionProducts = [];
    },
    onDeleteProduct(claimProduct) {
      const index = this.claimProducts.indexOf(claimProduct);
      this.claimProducts.splice(index, 1);
    },
    onDuplicateProduct(claimProduct) {
      let claimProductCopy = { ...claimProduct };
      this.claimProducts = this.claimProducts.concat(claimProductCopy);
    },
    onSubmit() {
      this.isBusy = true;
      this.errors = {};

      let postForm = {};
      postForm.promotionKey = this.promotion.promotionKey;
      // postForm.claimStage - allow this to be determined
      postForm.participantTypeKey = "950";
      postForm.participantStatus = { name: "ENR" };
      postForm.zeroSales = false;
      postForm.uploads = this.claimUploads.existing;
      postForm.claimProducts = this.claimProducts;
      postForm.claimFieldValues = this.getClaimFieldValues();
      postForm.reuseParticipant = true;
      postForm.optIn = this.optIn;
      postForm.browserSource = window.location.href;
      postForm.browserReferer = document.referrer || this.getReferrerFromQueryString();
      postForm.emailTemplateParticipantForm = {
        emailTemplateKey: "END_USER_CLAIM_SUBMISSION"
      };

      this.$api
        .postWithCaptcha("/api/claims/submit/public", postForm, null, "eurClaim")
        .then(({ data }) => {
          this.form = data;
          this.claimNumber = data.claimKey;
          this.isBusy = false;
          this.claimSubmitted = true;
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
          this.$vuetify.goTo(0);
          this.isBusy = false;
        });
    },
    getReferrerFromQueryString() {
      var queryString = window.location.search.substring(1);
      var params = new URLSearchParams(queryString);

      if (params.has("referrer")) {
        return params.get("referrer");
      } else {
        return null;
      }
    },
    getClaimFieldValues() {
      const claimFieldsClone = this.claimFields.slice();
      let cfValues = [];
      claimFieldsClone.map(claimField => {
        let cfValue = {
          claimField: { id: claimField.id },
          value:
            this.claimFieldValues[claimField.id] && this.claimFieldValues[claimField.id].name
              ? this.claimFieldValues[claimField.id].name.trim()
              : this.claimFieldValues[claimField.id]
              ? this.claimFieldValues[claimField.id].trim()
              : this.claimFieldValues[claimField.id]
        };
        cfValues.push(cfValue);
      });
      return cfValues;
    },
    fetchData() {
      this.isBusy = true;

      let identifier = this.$route.params.id;

      let promotionFetchingPromise = null;
      if (this.isNumber(identifier)) {
        promotionFetchingPromise = this.$api.get("/api/promotions/" + identifier + "/public");
      } else {
        promotionFetchingPromise = this.$api.get("/api/promotions/byPromotionKey/" + this.$route.params.id + "/public");
      }

      promotionFetchingPromise.then(({ data }) => {
        this.promotion = data.promotion;
        this.promotionId = this.promotion.id;
        this.defaultTermsAndConditions = data.programTermsAndConditions;
        this.supportingDocumentation = !this.promotion.skipClaimDocumentation;
        this.promotion.promotionProducts = data.promotionProducts.filter(item => item.effective == true);

        this.claimFields = data.claimFields.sort((a, b) => a.rank - b.rank);

        this.claimFieldsByRow = [];
        let rowCount = -1;
        let colCount = 0;

        this.claimFields.forEach((claimField, i) => {
          if (!claimField.cols || claimField.cols < 1 || claimField.cols > 12) {
            claimField.cols = 12;
          }
          if (i == 0 || this.claimFields[i].category != this.claimFields[i - 1].category) {
            claimField.newCategory = true;
          } else {
            claimField.newCategory = false;
          }
          if (claimField.newCategory || colCount + claimField.cols > 12) {
            rowCount++;
            colCount = claimField.cols;
          } else {
            colCount += claimField.cols;
          }
          if (!this.claimFieldsByRow[rowCount]) {
            this.claimFieldsByRow[rowCount] = [];
          }
          this.claimFieldsByRow[rowCount].push(claimField);
          if (claimField.defaultValue && claimField.defaultValue.length > 0) {
            this.claimFieldValues[claimField.id] = claimField.defaultValue;
          }
        });
        if (this.selectedParticipant) {
          if (this.selectedParticipant.optIn == undefined) {
            this.optIn = true;
          } else {
            this.optIn = this.selectedParticipant.optIn;
          }
        }

        if (this.promotion.uploads && this.promotion.uploads.length > 0) {
          this.promotion.uploads.forEach(upload => {
            if (upload.contentType.startsWith("image")) {
              Vue.set(this.promotion, "banner", upload);
            } else if (upload.contentType.startsWith("application")) {
              Vue.set(this.promotion, "download", upload);
            }
          });
        }

        this.isBusy = false;
      });
    },
    updateSonyDealerFieldsVisibility(v) {
      let selectedItem = v.selectedItem;
      let other = v.other;
      for (let claimField of this.claimFields) {
        if (claimField.name && claimField.name.startsWith("Sony Dealer") && claimField.name.trim() != "Sony Dealer") {
          claimField.hidden = !v.other;
          if (!other) {
            if (selectedItem) {
              switch (claimField.name) {
                case "Sony Dealer Name":
                  this.$set(this.claimFieldValues, claimField.id, selectedItem.name);
                  break;
                case "Sony Dealer Address 1":
                  this.$set(this.claimFieldValues, claimField.id, selectedItem.address1);
                  break;
                case "Sony Dealer Address 2":
                  this.$set(this.claimFieldValues, claimField.id, selectedItem.address2);
                  break;
                case "Sony Dealer City":
                  this.$set(this.claimFieldValues, claimField.id, selectedItem.city);
                  break;
                case "Sony Dealer State / Province":
                  this.$set(this.claimFieldValues, claimField.id, selectedItem.region);
                  break;
                case "Sony Dealer Country":
                  this.$set(this.claimFieldValues, claimField.id, selectedItem.country);
                  break;
                case "Sony Dealer Postal Code":
                  this.$set(this.claimFieldValues, claimField.id, selectedItem.postalCode);
                  break;
              }
            }
          }
          if (other || !selectedItem) {
            this.claimFieldValues[claimField.id] = null;
          }
        }
      }
      this.$forceUpdate();
    },
    getUploads(promotion) {
      return this.$api
        .get("/api/promotions/" + promotion.id + "/uploads")
        .then(({ data }) => {
          Vue.set(promotion, "uploads", data._embedded.uploads);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onCancel() {
      this.$router.push({ name: "eurList" });
    },
    showUploadedFilesDialog() {
      this.$api
        .get("/api/promotions/" + this.promotionId + "/uploads/")
        .then(({ data }) => {
          this.uploads = data._embedded.uploads;
        })
        .then(() => {
          this.uploadedFilesDialog = true;
        });
    },
    formatSerialNumber(item) {
      if (item.serialNumber) {
        if (item.serialNumber.startsWith("S01")) {
          item.serialNumber = item.serialNumber.substring(3);
        }
        if (item.serialNumber.length < 7) {
          item.serialNumber = item.serialNumber.padStart(7, "0");
        }
      }
    },
    onClear() {
      this.participantSelectedToClaimOnBehalf = null;
    },
    backToDashboard() {
      this.$router.push({ name: "dashboard" });
    },
    onEndUserDelete(v) {
      if (v.id == this.selectedEndUser.id) {
        this.selectedEndUser = null;
      }
    },
    keyHandler(v) {
      if (v.key == " " || v.key == ",") {
        event.preventDefault();
      }
    },
    loadZeroSalesMonths() {
      this.zeroSalesItems = [];
      let month = moment();
      month.subtract(1, "months");
      for (let i = 0; i < 3; i++) {
        this.zeroSalesItems.push({
          text: month.format("MMMM YYYY"),
          value: month.format("YYYY-MM-[01]")
        });
        month.add(1, "months");
      }
    }
  }
};
</script>
